<template>
  <v-dialog
    v-model="show"
    @click:outside="fechar"
    @keydown.esc="fechar"
    width="85%"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-2">
        <span class="px-2">
          {{ $tc("global.contasPagar") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-row class="pt pb-4">
          <v-col cols="4">
            <SearchFornecedores
              v-if="!mounted"
              style="max-width: 450px"
              class="mr-2"
              :readonly="cliente != null"
              :fornecedor_id="fornecedor_id"
              :fornecedor_nome="fornecedor_nome"
              :fornecedor_async.sync="selectedFornecedor"
            />
          </v-col>
        </v-row>
        <v-divider v-if="!loading && selectedFornecedor"></v-divider>
        <v-data-table
          v-if="!loading && selectedFornecedor"
          v-model="contasSelected"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="contas"
          item-key="id"
          show-select
          sort-by="id"
          :items-per-page="10"
          class="data-tables"
          :item-class="row_classes"
        >
          <template v-slot:item.id="{ item }">
            <div class="d-flex justify-space-between">
              <span>
                {{ item.id }}
              </span>
              <v-tooltip
                v-if="item.origem_tabela == 'ORDEM_SERVICO'"
                bottom
                color="darkblue"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    icon
                    @click.stop="openVendaInfo(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Informações da venda</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.data_vencimento="{ item }">
            <div class="d-flex align-center">
              <div v-if="item.data_vencimento">
                {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
              </div>
            </div>
          </template>
          <template v-slot:item.data_lancamento="{ item }">
            <div class="d-flex align-center">
              <div v-if="item.data_lancamento">
                {{ item.data_lancamento | dateFormat("dd/MM/yyyy") }}
              </div>
            </div>
          </template>
          <template v-slot:item.moeda_sigla="{ item }">
            <div v-if="item.moeda_sigla">
              {{ item.moeda_sigla }}
            </div>
          </template>
          <template v-slot:item.valor="{ item }">
            <div v-if="item.moeda_sigla == 'G$' && item.valor">
              {{ item.valor | guarani }}
            </div>
            <div v-else-if="item.valor">
              {{ item.valor | currency }}
            </div>
          </template>

          <template v-slot:item.desconto="{ item }">
            <div v-if="item.moeda_sigla == 'G$' && item.desconto">
              {{ item.desconto | guarani }}
            </div>
            <div v-else-if="item.desconto">
              {{ item.desconto | currency }}
            </div>
          </template>
          <template v-slot:item.valor_pago="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.valor_pago | guarani }}
            </div>
            <div v-else-if="item.valor_pago">
              {{ item.valor_pago | currency }}
            </div>
          </template>

          <template v-slot:item.saldo="{ item }">
            <div v-if="item.moeda_sigla == 'G$' && item.saldo">
              {{ item.saldo | guarani }}
            </div>
            <div v-else-if="item.saldo">
              {{ item.saldo | currency }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          :disabled="disableConfirmar"
          class="white--text"
          @click="enviarItensParaBaixa"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $tc("global.confirmar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import { getContasPagar } from "@/api/contas-pagar/contas_pagar.js";
import { getFornecedorByClienteId } from "@/api/fornecedores/fornecedores.js";
import { mapGetters } from "vuex";

export default {
  name: "ContasPagarEmLoteAf",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
    },
  },

  components: {
    SearchFornecedores: () =>
      import("@/components/fields/SearchFornecedores.vue"),
  },

  data() {
    return {
      contas: [],
      loading: true,
      mounted: true,
      search: "",
      selectedFornecedor: null,
      contasSelected: [],
      dialogConta: false,
      fornecedor_nome: null,
      fornecedor_id: null,
    };
  },

  computed: {
    disableConfirmar() {
      return this.contasSelected.length == 0;
    },

    headers() {
      return [
        {
          text: "Id",
          value: "id",
          width: "70px",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
          width: "150px",
        },
        {
          text: this.$tc("global.data-vencimento"),
          align: "start",
          value: "data_vencimento",
          width: "150px",
        },
        {
          text: this.$tc("global.historico"),
          width: "150px",
          value: "historico",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          width: "150px",
          align: "start",
        },
        {
          text: this.$tc("global.valor"),
          width: "150px",
          value: "valor",
        },
        {
          text: this.$tc("global.data-pagamento"),
          align: "start",
          width: "150px",
          value: "data_pagamento",
        },
        {
          text: this.$tc("global.desconto"),
          width: "150px",
          align: "right",
          value: "desconto",
        },
        {
          text: this.$tc("global.parcial"),
          width: "150px",
          align: "right",
          value: "valor_pago",
        },
        {
          text: this.$tc("global.saldo"),
          width: "150px",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },

  methods: {
    enviarItensParaBaixa() {
      let baixaItens = [];
      for (let i = 0; i < this.contasSelected.length; i++) {
        let conta = this.contasSelected[i];
        let baixaItem = {
          id: conta.id,
          tipo: "conta_pagar",
          cliente_fornecedor: conta.fornecedor_nome,
          fornecedor: this.selectedFornecedor,
          condicao_pagamento: 1,
          valor: conta.saldo,
          tipo_af: "S",
          moeda_sigla: conta.moeda_sigla,
          conta: conta,
        };
        baixaItens.push(baixaItem);
      }
      this.$emit("add", baixaItens);
      this.fechar();
    },
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      }
    },
    async fetchContasPagar() {
      this.loading = true;
      return getContasPagar(
        `?fornecedor_id=${this.selectedFornecedor.id}&nao_pagas=${true}`
      )
        .then((response) => {
          this.contas = response;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fechar() {
      this.$emit("update:show", false);
    },
  },

  watch: {
    selectedFornecedor(val) {
      if (val) {
        this.fetchContasPagar();
      }
    },
  },

  async mounted() {
    if (this.cliente) {
      await getFornecedorByClienteId(this.cliente.id).then((response) => {
        if (response) {
          this.fornecedor_nome = response.nome;
          this.fornecedor_id = response.id;
        }
      });
    }
    this.mounted = false;
  },
};
</script>

<style></style>
